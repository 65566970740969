import React from "react";
import Container from "../components/Container"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <Container>
      <div className="row" data-is-root-path={isRootPath}>
        <div className="col-12">
          <main>{children}</main>
        </div>
      </div>
    </Container>
  )
}

export default Layout
